import React, { useMemo, useState, useEffect } from "react";
import Loader from "../Components/Loader";
// import { useAxiosGet } from "../Hooks/HttpRequests";
import { Container } from "reactstrap";
import { SelectColumnFilter } from "../Components/filters";
import TableContainer_da from "../Components/TableContainer";
import { apirurl_da, fetchInterval } from "../config";

function getMonthShortName(monthNo) {
  const date = new Date();
  date.setMonth(monthNo - 1);

  return date.toLocaleString("en-US", { month: "short" });
}

const axios = require("axios");
const qs = require("qs");

function Home() {
  let data = qs.stringify({});
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: apirurl_da,
    headers: {
      "x-api-key": "NjQ3M2VmZGY2YjgwNGJh",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  const [dadata, setDadata] = useState({});

  useEffect(async () => {
    async function fetchData() {
      await axios(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          setDadata({
            loading: false,
            data: JSON.stringify(response.data),
            rejectUnauthorized: false,
            error: false,
          });
        })
        .catch(() => {
          setDadata({
            loading: false,
            data: null,
            error: true,
          });
        }
        );
    }
    fetchData();
  }, []);
 
  let content = null;
  const columns = useMemo(
    () => [
      {
        Header: "Office",
        accessor: "office",
        width: 5,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Name",
        accessor: "name",
        width: 5,
        disableSortBy: false,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Datetime",
        accessor: "datetime",
      },
      {
        Header: "Year",
        accessor: "year",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Month",
        accessor: "month",
        width: 5,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Day",
        accessor: "day",
        disableSortBy: true,
        width: 5,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        // Cell: row => {
        //   row.styles['backgroundColor'] = row.status === 'online' && row.value < -20 ? '#cc3300' : 'inherit';
        //   return row.status === 'online' ? row.value : null;
        // }
      },
    ],
    []
  );

  // error handling while there's error, e.g. use http for https api url
  if (dadata.error) {
    content = (
      <div>
        <div className="bg-blue-300 mb-2 p-3">
          If you see this error. Please remember to create your own{" "}
          <a href="https://mockapi.io/">mock API</a>.
        </div>
        <div className="bg-red-300 p-3">
          There was an error please refresh or try again later.
        </div>
      </div>
    );
  }

  // will show loading animation while calling the API
  if (dadata.loading) {
    content = <Loader />;
  }

  // data ready
  if (dadata.data) {
    var _daData = JSON.parse(dadata.data);
    // debugger
    // var d0 = _data[0].office
    var daData = [];

    _daData.forEach((da) => {
      var mth, mm, yyyy, dd;
      try {
        var nm = da.name;
        nm = nm.replace("Sho", "Soh");
        mth = da.date.split("-");
        yyyy = parseInt(mth[0]);
        const yytest = yyyy > 2022 && yyyy < 3022;
        if (yytest && String(nm).toLowerCase() !== "dareport") {
          mm = mth[1];
          mm = parseInt(mm.split(" ")[0]);
          dd = parseInt(mth[2]);
          daData.push({
            office: da.office,
            name: nm,
            datetime: da.date,
            month: getMonthShortName(mm),
            year: yyyy,
            day: dd,
          });
          // console.log("month:::", parseInt(mth2))
        }
      } catch (err) {
        console.log(err);
      }
    });
    // console.log("daData:::", daData)
    content = (
      <Container style={{ marginTop: 20 }}>
        <TableContainer_da columns={columns} data={daData} />
      </Container>
    );
  }
  return <div className="md:flex flex-wrap md:-mx-3">{content}</div>;
}

export default Home;
