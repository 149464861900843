import React, { useEffect } from "react";

function About(){
    useEffect(() => {
      window.location.href = "https://nms.ectivisecloud.com/";  }, []);
    
      return (
        <div>
          {<h2>espl dooraccess history</h2>}
        </div>
      );
    }

export default About
