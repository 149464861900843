import { useState } from 'react';
const useLocalStorage = false
export default function useToken() {
  const getToken = () => {
    // const tokenString = sessionStorage.getItem('token');
    const tokenString = useLocalStorage?localStorage.getItem("token"): sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    // console.log(userToken)
    let usertoken = userToken || {token:false}
    if(useLocalStorage)
      localStorage.setItem('token', JSON.stringify(usertoken));
    else
      sessionStorage.setItem('token', JSON.stringify(usertoken));
    setToken(usertoken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}

