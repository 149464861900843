import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Views/Home'
import About from './Views/About'
// import HelloWorld from './Components/HelloWorld'
import useToken from './useToken';
import Login from "./Components/Login/Login";
import {noLogin} from "./config"

function App() {
  const { token, setToken } = useToken();
  if (!noLogin && !token) {
    return <Login setToken={setToken} />
  }
  return (
    <div className="relative pb-10 min-h-screen">
      <Router>

        <Header />

        <div className="p-3">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/Home">
              <Home />
            </Route>
            {/* <Route path="/HelloWorld">
              <HelloWorld />
            </Route> */}
            <Route path="/about">
              <About />
            </Route>
          </Switch>
        </div>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
