const site = 'feb' // 'm2' or 'feb'
const localized = true
const noLogin = true
let endpoint =  `/api/inventory/get${site}corenes`
//20220623
//1) change api to api.ectivisecloud.com
const useNMSserver = false;
const useLocalAPI = false;
var urllocal = 'http://192.168.190.24:8082'
// var urllocal = 'http://192.168.190.24'
const urlapi = 'https://api.ectivisecloud.com'
const urlnms = 'https://nms.ectivisecloud.com'
const apiheaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
  // 'Cookie': 'connect.sid=s%3AYDkwnvZtoSkKr05mVIqmWH7kEk-NKXu5.44g1sp3S0IK46iCRZ1ldxlwYhnB%2FYw%2FMZdUvZHzHxbQ',
  'Access-Control-Allow-Origin': '*',
  'x-api-key': 'NjQ3M2VmZGY2YjgwNGJh',
  'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
  'Access-Control-Allow-Headers': 'Content-Type',
  'Access-Control-Allow-Credentials': true

} 
if (useLocalAPI) var apiurl =  urllocal +  endpoint 
else
apiurl = useNMSserver
    ? urlnms + endpoint
    : urlapi + endpoint;

const apiurl_da = urlapi + "/api/dooraccess/getDoorAccess_esplNjQ3M2VmZGY2YjgwNGJh"

var baseurl = urlapi;

module.exports = {
  localdeviceurl: `https://192.168.190.21/device/`,
  localized: localized, //localization will show the device id link to return to the librenms device show
  baseurl: urlapi,
  apiurl: apiurl,
  apirurl_da: apiurl_da,
  apiheaders: apiheaders,
  // noLogin: noLogin,
  debug: false,
  noLogin: false,
  fetchInterval:14400
}
