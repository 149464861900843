import React, {Fragment} from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
import {Table, Row, Col, Button, Input, CustomInput} from 'reactstrap';
import {Filter, DefaultColumnFilter} from './filters';

const TableContainer_da = ({columns, data}) => {

  const zero1000 = [...Array(1000).keys()]; 
  const expanded = zero1000.map(i=>{
    return{
      i:true
    }
  })

  const {
    // getTableProps,
    // getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {Filter: DefaultColumnFilter},
      initialState: {pageIndex: 0, pageSize: 1000, expanded},
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Table  className="table-bordered table-striped">
        <thead>
        {headerGroups.map((headerGroup, key) => (
          <tr key={key}>
            {headerGroup.headers.map((column,key) => (
              <th key={key}>
                <div>
                  {column.render('Header')}
                  {generateSortingIndicator(column)}
                </div>
                <Filter column={column}/>
              </th>
            ))}
          </tr>
        ))}
        </thead>

        <tbody >
        {page.map((row) => {
          prepareRow(row);
          // debugger;
          // console.log(row)
          // console.log(row.getToggleRowExpandedProps())
          return (
            <Fragment key={row.getRowProps().key}>
              <tr>
                {row.cells.map((cell,key) => {
                  // debugger

                  // console.log(cell.value)
                  return (
                    <td key={key}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
              {row.isExpanded && (
                <tr>
                  <td colSpan={visibleColumns.length}>
                  {/* {renderRowSubComponent(row)} */}
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
        </tbody>
      </Table>

      <Row style={{maxWidth: 1000, margin: '0 auto', textAlign: 'center'}}>
        <Col md={3}>
          <Button
            color='primary'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Button>
          <Button
            color='primary'
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {'<'}
          </Button>
        </Col>
        <Col md={2} style={{marginTop: 7}}>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <Input
            type='number'
            min={1}
            style={{width: 70}}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col md={2}>
          <CustomInput
            type='select'
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[300, 500, 1000, 5000, 10000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col md={3}>
          <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button
            color='primary'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TableContainer_da;
