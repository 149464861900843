import React from 'react'
import Navigation from './Navigation'
import { Link } from 'react-router-dom'
const espllogo = "/logoEctivise.png"

function Header() {
  const queryParams = new URLSearchParams(window.location.search);
  let site = queryParams.get('site')
  // site = null
  // if (site) { console.log("site=", site) }
  var sitelog = null
  let sl = null
  if (sitelog)
    sl = <img className="full-width height: md:m-auto" src={sitelog} alt="uob logo" />
  return (
    <header className="border-b p-3 flex justify-between items-center flex-auto">
      <Link to="/About" className="font-bold">
        <div className="row pl-48">
          {sl}
          {/* <img className="full-width height: md:m-auto" src={lglogo} alt="uob logo" /> */}
          <img className="full-width height: md:m-auto" src={espllogo} alt="ectivise logo" />
          <div className="px-4 text-2xl">Door Access Management System</div>
        </div>
      </Link>

      <Navigation />
    </header>
  )
}

export default Header
